import AOS from "aos";
import { useEffect } from 'react';
import './App.css';


const Grievance = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (
        <>
            <div className='h-20'></div>
            <div>
                <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                    Grievance Redressal Officer
                </div>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20' data-aos='fade-up'>
                    <p className='grev mt-10'>For any grievance or dispute arising out of any decision by a functionary of IARC, you may contact:</p>

                    <p className='name mt-16'>Mr. Sudhir Kushwaha</p>
                    <ul className='points'>
                        <li className='mb-4 mt-5'>
                            Vice President
                        </li>
                        <li className='mb-4'>
                            International Asset Reconstruction Company Private Limited
                        </li>
                        <li className='mb-4'>
                            406, DLF South Court, Saket,
                        </li>
                        <li className='mb-4'>
                            New Delhi-110017
                        </li>
                        <li className='mb-4'>
                            Telephone:  011-41199401
                        </li>
                        <li className='mb-4'>
                            Email: grievanceofficer@iarc.co.in
                        </li>
                    </ul>

                    <p className='name mt-16'>Mr. Neeraj I. Mohan</p>
                    <ul className='points'>
                        <li className='mb-4 mt-5'>
                            Managing Director & Chief Executive Officer
                        </li>
                        <li className='mb-4'>
                            International Asset Reconstruction Company Private Limited
                        </li>
                        <li className='mb-4'>
                            406, DLF South Court, Saket,
                        </li>
                        <li className='mb-4'>
                            New Delhi-110017
                        </li>
                        <li className='mb-4'>
                            Telephone (Board): +91-11-40235 000
                        </li>
                        <li className='mb-4'>
                            Email: mdceo@iarc.co.in
                        </li>
                    </ul>

                    <p className='grev mt-24'>Grievance Redressal Officer of the Reserve Bank of India, Regional Office, New Delhi</p>
                    <p className='points'>(For appeal in case complaint/dispute is not redressed within a period of one month)</p>

                    <p className='name mt-10'>General Manager</p>
                    <ul className='points'>
                        <li className='mb-4 mt-5'>
                            Department of non-banking supervision
                        </li>
                        <li className='mb-4'>
                            Reserve Bank of India, Regional Office
                        </li>
                        <li className='mb-4'>
                            6, Sansad Marg,
                        </li>
                        <li className='mb-4'>
                            New Delhi-110001
                        </li>
                        <li className='mb-4'>
                            Telephone (Board): +91-11-23714 456
                        </li>
                    </ul>

                    <p className='name mt-10'>Grievance Redressal Day</p>
                    <p className='points'>IARC has designated the Last Friday of each month as 'Grievance Redressal Day', allowing customers to visit its branches or offices without prior appointments for grievance resolution.</p>

                </div>
            </div>
        </>
    );
}

export default Grievance;