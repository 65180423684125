import './App.css';


const CodeOfConduct = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>
                Code of conduct for Directors and Senior Management Personnel of International Asset Reconstruction Company Pvt. Ltd.
            </div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <p className='name mb-5'>PURPOSE</p>
                    <p className='pointss mb-5'>
                        The purpose of this Code is to serve as a guide to the Directors and Senior Management Personnel
                        of International Asset Reconstruction Company Pvt. Ltd. (IARC) on the principles of integrity,
                        transparency and business ethics and to set up standards for compliance of Corporate Governance.
                        Issues of Corporate Governance and challenges posed by the increasing awareness and importance
                        for greater transparency, accountability and disclosure in companies together with the
                        responsibilities placed on the Board under the provisions of the Companies Act 2013 have created
                        need to evolve a Code of Conduct (Code) for all members of the Board and senior management of
                        IARC.
                    </p>
                    <p className='name mb-5'>APPLICABILITY</p>
                    <p className='pointss mb-5'>
                        The Code shall come into force with effect from July 07, 2014 and shall be applicable to the following:-
                    </p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            All the directors of the company
                        </li>
                        <li className='mb-5'>
                            All Senior Management Personnel of the Company and shall include employees of the Company,
                            who are at the level of one rank below the members of the Board of Directors. This will include
                            all employees at the level of Presidents and above.
                        </li>
                    </ul>
                    <p className='name mb-5'>COLLECTIVE RESPONSIBILITY &amp; DUTIES</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            All Directors and Managing Director &amp; CEO acting collectively as a Board, function under the
                            principle of collective responsibility and will always act in the interest of the Company.
                        </li>
                        <li className='mb-5'>
                            In making its decisions, the Board shall take into account, and balance the various and
                            sometimes conflicting interests of all the stakeholders.
                        </li>
                        <li className='mb-5'>
                            The members of the Board, shall exercise their duties with due and reasonable care, skill and
                            diligence and shall exercise independent judgment and highest standards of integrity and ethics.
                        </li>
                        <li className='mb-5'>
                            The members of the Board shall act in good faith in order to promote the objects of the
                            company for the benefit of its members as a whole, and in the best interests of the company, its
                            employees, the shareholders, the community and for the protection of environment.
                        </li>
                        <li className='mb-5'>
                            The Board will rely in good faith on Senior Management for information and disclosures for its
                            evaluation and decision-making.
                        </li>
                        <li className='mb-5'>
                            Directors must not be disqualified from being appointed as directors under Section 164 and
                            other applicable provisions of the Companies Act, 2013.
                        </li>
                        <li className='mb-5'>
                            In case of resignation of any director from the directorship of IARC, he/she will forward a copy
                            of his resignation letter, along with detailed reasons for the resignation, also to the Registrar of
                            Companies, in compliance of the provisions of the Companies Act 2013. The resignation will be
                            effective from the date it is received by IARC / date as mentioned in the letter, whichever is
                            earlier.
                        </li>
                    </ul>
                    <p className='name mb-5'>HONEST AND ETHICAL INTEREST AND FAIR DEALING</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            The Board of Directors and Senior Management Personnel will pro-actively promote and set an
                            example of ethical behavior and integrity to the employees of the Company.
                        </li>
                        <li className='mb-5'>
                            In the discharge of their official duties and obligations the Board and Senior Management
                            Personnel will exhibit and promote the highest standards of honest and ethical conduct. They
                            will endeavor to deal fairly and not seek to take unfair advantage of the Company through
                            manipulation, concealment, abuse of privileged information, misrepresentation of material facts
                            or any other unfair dealing.
                        </li>
                        <li className='mb-5'>
                            All applicable laws, rules and regulations will be complied with in letter and in spirit in all the
                            areas in which the Company operates, and the Board will rely for such compliance on the
                            information and disclosures made by Senior Management.
                        </li>
                    </ul>
                    <p className='name mb-5'>DISCLOSURE AND TRANSPARENCY</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            The President &amp; CFO and other Senior Management Personnel of the company will provide the
                            Board, shareholders, and other stakeholders with accurate, complete, objective, relevant and
                            timely information in an easily understandable form, by means of periodic reports and other
                            communication subject to relevant laws and guidelines on the same.
                        </li>
                        <li className='mb-5'>
                            While making such disclosures, all material facts will be stated and there will be no concealment
                            or misrepresentation of information or data.
                        </li>
                    </ul>
                    <p className='name mb-5'>CONFLICT OF INTEREST</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className="mb-5">
                            A director of a company shall not involve in a situation in which he/she may have a direct or
                            indirect interest that conflicts, or possibly may conflict, with the interests of the company.
                        </li>
                        <li className="mb-5">
                            As a general rule, Managing Director &amp; CEO and Senior Management Personnel should avoid
                            conducting business of the Company with a close relative or with a business in which a relative is
                            associated in any significant role.
                        </li>
                        <li className="mb-5">
                            The Managing Director &amp; CEO and Senior Management Personnel will devote their full attention
                            and time to the business interests of the Company. They will not render any professional service
                            to or accept remuneration or compensation in cash or kind from suppliers, customers,
                            consultants, or competitors to the Company.
                        </li>
                        <li className="mb-5">
                            The Board and Senior Management Personnel will not take part in any activity that enhances or
                            supports a competitor’s position and shall disclose to the Company any such activity or receipt
                            of any gift irrespective of its value as a matter of principle.
                        </li>
                        <li className="mb-5">
                            The Directors and Senior Management Personnel shall not accept any offer, gift of value,
                            money, payment or promise to pay from borrowers, suppliers, vendors, or authorities, that is
                            perceived as intended directly or indirectly to influence any business decision of IARC.
                            Inexpensive gifts, business meals, celebratory events, and official entertainment, provided they
                            are not excessive or create an appearance of impropriety and do not breach the Code, are permitted. Gifts
                            given or received should be appropriate to the circumstances and never excessive.
                        </li>
                    </ul>
                    <p className='name mb-5'>INSIDER TRADING</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className="mb-5">
                            None of the Directors or the Senior Management Personnel shall derive any benefit nor assist
                            others in deriving benefit by giving investment advice, based on possession of information about
                            the Company, which is not in public domain and constitutes insider information. All Directors
                            and Senior Management Personnel will comply with the Company’s Code for Prevention of
                            Insider Trading.
                        </li>
                    </ul>
                    <p className='name mb-5'>
                        OTHER DIRECTORSHIPS
                    </p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className="mb-5">
                            The Directors will disclose their engagements, either as Director or in any other capacity, to the
                            Company in accordance with the provisions of the Companies Act, 2013.
                        </li>
                        <li className="mb-5">
                            The total number of directorships of any Director in other companies will not exceed the
                            maximum limit as specified under the Companies Act 2013, as amended from time to time.
                        </li>
                    </ul>
                    <p className='name mb-5'>POWERS AND LIABILITIES OF DIRECTORS</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            The Board of Directors shall exercise their powers in accordance with the provisions contained
                            in this behalf in the Companies Act 2013, or in the Memorandum and Articles of Association of
                            IARC, or as per any regulations not inconsistent therewith and duly made thereunder, including
                            regulations made by IARC in general meeting.
                        </li>
                        <li className='mb-5'>
                            The Board shall not exercise any power or do any act or thing which is directed or required, in
                            terms of the Companies Act 2013 or the Memorandum or Articles of the Company or otherwise,
                            to be exercised or done by IARC in General Meeting.
                        </li>
                        <li className='mb-5'>
                            The directors will be liable for contravention of any provisions of the Companies Act 2013 in
                            case they are aware of such contravention by virtue of the receipt by them of any proceedings
                            of the Board or participation in such proceedings and have not objected to the same.
                        </li>
                        <li className='mb-5'>
                            The directors will be liable for offences, if any, which may have occurred during their tenure,
                            even after resignation.
                        </li>
                    </ul>
                    <p className='name mb-5'>DISQUALIFICATIONS OF DIRECTORS</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            A director will be disqualified from being appointed / continuing as director on Board of IARC
                            and will vacate the said office, upon attracting any of the disqualifications as prescribed u/s 164
                            and 167 of the Companies Act 2013, illustrated as below:-<br />
                            <ul style={{ listStyleType: "disc", marginLeft: 20 }}>
                                <li className='mb-5'>
                                    Declaration as being of unsound mind by a competent court,
                                </li>
                                <li className='mb-5'>
                                    undischarged insolvent,
                                </li>
                                <li className='mb-5'>
                                    pending application for adjudication as an insolvent,
                                </li>
                                <li className='mb-5'>
                                    conviction by a court of any offence (under the Companies Act 2013), whether involving
                                    moral turpitude or otherwise, and sentenced in respect thereof to imprisonment for not less
                                    than six months (even if an appeal has been filed against that order) and a period of five
                                    years has not elapsed from the date of expiry of the sentence,
                                </li>
                                <li className='mb-5'>
                                    conviction of any offence and sentenced in respect thereof to imprisonment for a period of seven years or more,
                                </li>
                                <li className='mb-5'>
                                    passing of an Order (in force) disqualifying the person for appointment as a director,
                                </li>
                                <li className='mb-5'>
                                    Non-payment of any calls for a period of 6 months in respect of any shares of the company held by the director, whether alone or jointly with others,
                                </li>
                                <li className='mb-5'>
                                    Conviction of any offence dealing with related party transactions under section 188 at any time during the last preceding five years,
                                </li>
                                <li className='mb-5'>
                                    Not obtaining Director Identification Number,
                                </li>
                                <li className='mb-5'>
                                    Being a director of any company, which has not filed financial statements or annual returns
                                    for any continuous period of three financial years; or has failed to repay the deposits
                                    accepted by it or pay interest thereon or to redeem any debentures on the due date or pay
                                    interest due thereon or pay any dividend declared and such failure to pay or redeem
                                    continues for one year or more,
                                </li>
                                <li className='mb-5'>
                                    absenting himself / herself from all the meetings of the Board of Directors held during a period of twelve months with or without seeking leave of absence of the Board,
                                </li>
                                <li className='mb-5'>
                                    acting in contravention of the provisions of section 184 of the Companies Act 2013 relating
                                    to entering into contracts or arrangements in which he is directly or indirectly interested,
                                </li>
                                <li className='mb-5'>
                                    failure to disclose his interest in any contract or arrangement in which he is directly or
                                    indirectly interested, in contravention of the provisions of section 184,
                                </li>
                                <li className='mb-5'>
                                    disqualification under an order of a court or Tribunal,
                                </li>
                                <li className='mb-5'>
                                    removal in pursuance of provisions of the Companies Act 2013,
                                </li>
                                <li className='mb-5'>
                                    appointment as director by virtue of his holding any office or other employment in the
                                    holding, subsidiary or associate company, ceases to hold such office or other employment in
                                    that company.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p className='name mb-5'>CONFIDENTIALITY</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li>
                            Confidential information acquired in the course of one’s position, work and responsibility will
                            not be used for personal gain or advantage, nor be disclosed to a third party unless required by
                            law to do so. The matters discussed at the Board/Committee Meetings must not be disclosed
                            outside appropriate and reasonable circles. The Company’s confidential and proprietary
                            information shall not be inappropriately disclosed or used for the personal gain or advantage of
                            any Director or Senior Management Personnel.
                        </li>
                    </ul>
                    <p className='name mt-5 mb-5'>COMPANY PROPERTY</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            Every Director and Senior Management Personnel should endeavor to ensure that they use the
                            Company’s assets, proprietary information, and resources only for the legitimate business
                            purposes of the Company and not for their personal gains.
                        </li>
                    </ul>
                    <p className='name mb-5'>NON-COMPLIANCE</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            Suspected violations of this Code may be reported to the Chairman of the Board or the
                            Chairman of the Audit Committee. All reported violations shall be appropriately investigated.
                            Any waiver of this Code must be approved by the Board of Directors and publicly disclosed if
                            required by any applicable law or regulation.
                        </li>
                    </ul>
                    <p className='name mb-5'>ACKNOWLEDGEMENT OF RECEIPT OF THE CODE</p>
                    <ul style={{ listStyleType: "lower-alpha" }} className="ml-5">
                        <li className='mb-5'>
                            Every Director and Senior Management Personnel shall acknowledge receipt of this Code or any
                            modification(s) thereto, in the acknowledgement form and forward the same to the Company
                            Secretary indicating that they have received, read, understood, and agreed to comply with this
                            Code.
                        </li>
                    </ul>
                    <p className='name mb-5'>PLACEMENT OF THE CODE ON WEBSITE</p>
                    <p className='pointss mb-5'>
                        This Code and any amendment thereto shall be hosted on the website of the Company.
                    </p>
                </div>
            </section>
        </>
    )
}
export default CodeOfConduct