import './App.css';


const Fair = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>Fair Practice Code</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20 text-justify'>
                    <p className='name mb-10'>PREAMBLE</p>
                    <p className='pointss text-justify font-semibold'>
                        International Asset Reconstruction Company Private Limited <b>("IARC")</b> is an Asset Reconstruction
                        Company <b>(“ARC”)</b> and registered as such with the Reserve Bank of India <b>("RBI")</b>.
                        <br />
                        <br />
                        IARC is committed to functioning in conformity with the provisions of the Companies Act, 2013,
                        Securitization and Reconstruction of Financial Assets and Enforcement of Security Interest Act, 2002
                        (the “<b>SARFAESI Act</b>”) read with the Security Interest Enforcement Rules <b>("the Rules")</b>, the guidelines.
                        and directions issued by Reserve Bank of India to Asset Reconstruction Companies from time to time
                        (the <b>"RBI ARC Guidelines"</b>), and with all laws and regulations as applicable and as may be
                        amended/modified/updated/replaced from time to time.
                        <br />
                        <br />
                        Furthermore, the RBI Notification RBI/DOR/2024-25/116 DoR.FIN.REC.16/26.03.001/2024-25 of April
                        24, 2024 (as updated from time to time) (<b>"the “RBI ARC Master Circular 2024"</b>) requires ARCs to formulate Fair
                        Practices Code, to be approved by its Board of Directors, in order to achieve the highest standards of
                        transparency and fairness in dealing with all stakeholders and the same to be placed in public domain.
                        <br /><br />
                        In line with the RBI ARC Master Circular 2024, IARC has accordingly framed the following IARC Fair
                        Practices Code <b> (“FP Code”) </b> to be effective immediately upon approval by its Board of Directors
                        <b>(“Board”)</b>, and to supersede and replace existing code relating to Fair Practices. This FP Code shall
                        remain in effect until superseded or amended, or rendered inapplicable by amendments to laws, rules,
                        and regulations applicable to the relevant matters to an ARC.
                        <br /><br />
                        Notwithstanding anything contained herein, in case of a conflict between the provisions of this code
                        and extant laws, rules and regulations, the extant laws, rules and regulations (including RBI ARC Master
                        Circular 2024 on Fair Practices Code) shall prevail.
                    </p>
                    <ul className='list-[upper-alpha]'>
                        <li className='mb-5 mt-7 '> <b>OBJECTIVE</b> <p className='mt-5'>
                            The purpose of this FP Code is to serve as a guide to achieve highest standards of transparency and
                            fairness in dealing with all stakeholders, including shareholders, security receipt holders as well as the
                            borrowers in line with the guidance issued by RBI through its circular on Fair Practices to be adopted
                            by Asset Reconstruction companies.
                        </p></li>
                        <li className=''><b>FAIR PRACTICES RELATING TO:</b><br></br><br></br>
                            <ol className=''>
                                <li className='mb-5 list-decimal'><b> ACQUISITION OF FINANCIAL ASSET</b><p className='mt-4'>
                                    Acquisition of financial assets by IARC shall be in conformity with the IARC Financial Asset
                                    Acquisition Policy & Resolution Policies approved by the Board and with the prescribed regulatory guidelines effective on the given date. Any deviation from the said policy shall require approval of
                                    the Board.
                                    <br /><br />
                                    While acquiring any financial asset, IARC shall follow transparent and non-discriminatory practices
                                    (such as participation in auctions, submission of bids that are eligible for Swiss Challenge Method as
                                    per the extant rules, regulations, or guidelines, etc.), at the same time ensuring that IARC maintains
                                    arms-length distance in the process.
                                </p></li>
                                <li className='mb-5 list-decimal'><b>REALIZATION OF FINANCIAL ASSETS </b><p className='mt-4'>
                                    Realization of financial assets acquired by IARC shall be in conformity with the IARC Financial Asset
                                    Acquisition Policy & Resolution Policies approved by the Board and with the prescribed regulatory
                                    guidelines effective on the given date. Any deviation from the said policies shall require approval of
                                    the Board.
                                    <br /><br />
                                    In case of realization through enforcement of Security Interest under the SARFAESI Act, in order
                                    to enhance the transparency of the process, IARC shall:
                                    <ul className='!list-[lower-alpha]'>
                                        <li className='mb-5'>
                                            in respect of auction of secured assets, publicly solicit invitation for participation, to enable
                                            participation of as many prospective buyers as possible; in respect of sale of secured asset
                                            by any other mode permitted under the Act and the Rules, adhere to the relevant provisions
                                            in letter and spirit.
                                        </li>
                                        <li className='mb-5'>
                                            decide the terms and conditions of sale strictly in terms of the provisions of the SARFAESI
                                            Act and Rules and also adhere to any additional condition that might have been agreed to
                                            in the transaction documents entered into with investors in Security Receipts.
                                        </li>
                                        <li className='mb-5'>
                                            ensure that the spirit of Section 29A of Insolvency and Bankruptcy Code, 2016 is followed in
                                            dealing with prospective buyers.
                                        </li>
                                    </ul>
                                </p>
                                    <p>
                                        IARC shall ensure that the employees and agents are trained to deal with customers in an appropriate
                                        manner. IARC shall ensure that its employees or recovery agents do not resort to intimidation or
                                        harassment of any kind, either verbal or physical, against any person in their debt collection efforts,
                                        including acts intended to humiliate publicly or intrude upon the privacy of the debtors' family
                                        members, referees and friends, sending inappropriate messages either on mobile or through social
                                        media, making threatening and/ or anonymous calls, persistently calling the borrower and/ or calling
                                        the borrower before 8:00 a.m. and after 7:00 p.m. for recovery of overdue loans, making false and
                                        misleading representations, etc.
                                    </p>
                                </li>

                                <li className='mb-5 list-decimal'><b>RELEASE OF SECURITIES</b> <p className='mt-4'>
                                    Upon full repayment of dues or on full realisation of the outstanding loan amounts, IARC shall release
                                    all securities subject to any legitimate right or lien for any other claim it has against the Borrower. On
                                    exercising such right of set off, the borrower shall be given notice about the same with full particulars
                                    about the remaining claims and the conditions under which IARC is entitled to retain the securities till
                                    the relevant claim is settled/paid.
                                </p></li>
                                <li className='mt-5 list-decimal'><b>GRIEVANCE REDRESSAL</b> <p className='mt-4'>
                                    IARC has constituted a Grievance Redressal machinery as detailed below, within the organisation. IARC
                                    shall mention the name and contact number of designated grievance redressal officer on its website
                                    and also in its communication with the borrower(s) including intimation letter, settlement letter, etc.
                                </p>
                                    <p className='mt-4 list-decimal'>Grievance Redressal Machinery:</p>
                                    <ul className='!list-disc'>
                                        <li className='list-disc'>
                                            Mr. Sudhir Kushwaha shall be the Grievance Redressal Officer (“GRO”) of the company. Any
                                            grievance or dispute arising out of any decision by a functionary of IARC or any action of an
                                            outsourced agency or recovery agent appointed by IARC, may be brought to the notice of GRO
                                            by the concerned borrower. On receipt of such complaint / notice, GRO shall examine
                                            genuineness of the matter and take such action as may be required and furnish an appropriate
                                            response to the complainant.
                                        </li>
                                        <br />
                                        <li>
                                            Any grievance or dispute arising out of any decision by the GRO, may be brought to the notice
                                            of MD & CEO by the concerned borrower. On receipt of such complaint/notice, Management
                                            Committee of the Company shall examine the matter and take such action as may be required
                                            and furnish an appropriate response to the complainant.
                                        </li>
                                        <br />
                                        <li>
                                            If a complaint/dispute is not redressed within one month, the borrower may appeal to the
                                            General Manager, Department of Non-Banking Supervision of the Regional Office of Reserve
                                            Bank of India, under whose jurisdiction the registered office of IARC falls (currently New Delhi
                                            Regional Office of RBI).
                                        </li>
                                        <br />
                                        <li>
                                            The name and contact details of the Grievance Redressal Officer and the address of the relevant
                                            Regional Office of RBI will be displayed at the offices of IARC.
                                        </li>
                                        <br />
                                        <li>
                                            A record of complaints received from borrower and the follow up action taken will be maintained
                                            and a summary will be placed before the Audit Committee at quarterly intervals. The
                                            Compliance Officer of the company will be the coordinating officer for this purpose.
                                        </li>
                                    </ul>
                                    <br />
                                </li>
                                <div>
                                    <li className='mb-5 list-decimal'><b>CONFIDENTIALITY</b><p className='mt-4'>
                                        IARC, subsequent to its acquisition of financial assets, with adequate confidentiality terms with its
                                        investors shall keep the information acquired in the course of its business, strictly confidential and shall
                                        not disclose the same to anyone including other companies in the group, except when:
                                    </p>
                                    </li>
                                </div>
                            </ol>
                            <ul className='list-[upper-roman] md:ml-3'>
                                <li className='mb-1'>required by law;</li>
                                <li className='mb-1'>there is duty towards public to reveal information; or</li>
                                <li className='mb-1'>there is borrower’s permission.</li>
                            </ul>
                        </li>
                        <li className='mb-5 mt-5'><b>FAIR PRACTICES POLICIES</b><br></br><br></br><p className='mb-5'>
                            As mandated under the RBI ARC Master Direction 2024 on ARCs (and other applicable guidelines), IARC
                            has also put in place Board approved Fair Practices Policies covering the following areas:
                            <ol className='list-disc'>
                                <li className='mb-1 '>
                                    Management Fees, expenses, and incentives
                                </li>
                                <li className='mb-1'>
                                    Outsourcing and
                                </li>
                                <li className='mb-1 '>
                                    Code of Conduct for Recovery Agents.
                                </li>
                            </ol>
                        </p>
                        </li>
                        <ul className='fairnum'>
                            <li className='mb-5'><b>MANAGEMENT FEES, EXPENSES, AND INCENTIVES</b> -
                                IARC shall ensure that fees including
                                management fees, processing fees, asset reconstruction fees etc. as well as incentives and
                                expenses charged either to the Trust, seller of the financial asset or the security receipt (“SR”)
                                holders are reasonable, proportionate to the financial transactions and as per the terms of
                                contract entered into between the parties in conformity with the prescribed regulatory
                                guidelines effective on the given date. It shall be further ensured that such fees and incentives
                                charged towards the asset reconstruction or securitization activity come only from the recovery
                                effected from the underlying financial assets.
                                <br /><br />
                                <p>
                                    Management/Trusteeship fees will not exceed 10 (ten) percent per annum of total original SR
                                    value (not just IARC share). Processing fees will not exceed 10 (ten) percent per annum of total
                                    original SR value. All types of incentives, yield Income and upside on aggregate basis will not
                                    exceed 100% of total original SR value (not just IARC share). Any deviation from the said
                                    approved cap shall require approval of the Board.
                                </p>
                                <br />
                                <p>
                                    There is a Policy on Management Fees, Expenses and Incentives which forms part of the IARC
                                    Fair Practices Policies.
                                </p>
                            </li>
                            <li className='mb-5'><b>OUTSOURCING</b>{" "} - {" "}  IARC shall ensure that the outsourcing arrangements entered into, neither
                                diminishes its ability to fulfil its obligations to customers and the RBI, nor impede effective
                                supervision by RBI. If the outsourced agency is owned or controlled by a Director of IARC, the
                                same will be made part of the disclosures specified by RBI.
                                <br /><br />
                                <p>
                                    There is an Outsourcing Policy which forms part of the IARC Fair Practices Policies.
                                </p>
                            </li>
                            <li className='mb-5'><b>RECOVERY AGENTS </b> -  IARC has also adopted “Code of Conduct for Recovery Agents” and shall be
                                responsible for all actions of its Recovery Agents which are taken pursuant to instructions issued
                                by IARC from time to time. Further, IARC will also obtain an undertaking from the Recovery
                                Agents appointed by it, to abide by the Code of Conduct and require all its Recovery Agents, as
                                a part of such undertaking, to (among others):
                                <br /><br />
                                <ol>
                                    <li className='mb-5'>
                                        observe strict customer confidentiality;
                                    </li>
                                    <li className='mb-5'> handle their responsibilities with care and sensitivity, particularly in respect of aspects such
                                        as hours of calling, privacy of customer information, etc. </li>
                                    <li className='mb-5'> not induce adoption of uncivilized, unlawful, and questionable behaviour or recovery
                                        process; and</li>
                                    <li className='mb-5'> not resort to intimidation or harassment of any kind, either verbal or physical, against any
                                        person in their debt collection efforts, including acts intended to humiliate publicly or
                                        intrude upon the privacy of the debtors' family members, referees and friends, sending
                                        inappropriate messages either on mobile or through social media, making threatening and/
                                        or anonymous calls, persistently calling the borrower and/ or calling the borrower before
                                        8:00 a.m. and after 7:00 p.m. for recovery of overdue loans, making false and misleading
                                        epresentations, etc.
                                        <br /><br />
                                        <p>There is a Code of Conduct for Recovery Agents which forms part of the IARC Fair Practices
                                            Policies.</p>
                                    </li>
                                </ol>
                            </li>
                        </ul>

                        <li className='mb-5'><b>COMPLIANCE</b> <br></br><br></br> This Code shall be placed on IARC’s website. Compliance with this code is mandatory. Any employee
                            found in violation of the Policy shall face strict disciplinary action, which may extend to termination of
                            employment.</li>
                        <li className='mb-5'><b>REVIEW</b><br></br><br></br>
                            The Fair Practices Code and Fair Practices Policies shall be placed for review by the Board every
                            year, or as needed.
                        </li>
                    </ul>
                </div>
            </section >
        </>
    )
}
export default Fair