import './App.css';


const Whis = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'> Whistle Blower Policy</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <p className='name pb-5'>
                        Objective
                    </p>
                    <div className='pointss'>
                        <p className='mb-5'>This Whistle-Blower and Vigil Policy (<strong>“Policy”</strong>) has been designed to provide a secured channel of
                            communication which a stakeholder can use to share his/her genuine grievances, concerns and
                            objections with regard to unacceptable work behaviour, management practices, business practices,
                            physiologically adverse work conditions, and other such activities of reasonably negative nature
                            which may be seen to tarnish the image of the organization, its products, its social image, employee
                            morale and employee’s safety at the workplace.</p>
                        <ul>
                            <li>
                                Such instances include, but are not limited to:
                                <ul className='list-decimal'>
                                    <li className='mb-5'>wilful misuse of authority or discretion</li>
                                    <li className='mb-5'>
                                        actions which do not conform to high standards of professionalism and integrity
                                    </li>
                                    <li className='mb-5'>actions which may lead to incorrect financial reporting, are in violation of IARC's policies (including Code of Conduct & Ethics) or contrary to law/regulatory prescriptions</li>
                                    <li className='mb-5'>unlawful conduct</li>
                                    <li className='mb-5'>misconduct with other employees, customers or suppliers</li>
                                    <li className='mb-5'>misrepresentation of facts and information related to IARC that causes breach of trust of customer or any other such third party</li>
                                    <li className='mb-5'>unauthorized use of IARC's funds</li>
                                    <li className='mb-5'>criminal offence such as fraud, theft, corruption,discrimination against an employee or customer or service provider on the grounds of sex, religion, caste or disability</li>
                                    <li className='mb-5'>any other form of improper action or conduct.</li>
                                </ul>
                            </li>
                            <li className='mb-5'>Directors and employees have a duty to remain vigilant about any such occurrences and to report
                                the same to the concerned authorities as per this Policy.</li>
                            <li className='mb-5'>The provisions of this Policy neither release employees from their duty of confidentiality nor can
                                these be used to raise malicious or unfounded allegations.</li>
                        </ul>
                        <p className='name mt-5 mb-5'>
                            Who can report
                        </p>
                        <p className='mb-5'>
                            Any director or any permanent or temporary employee (including part-time and contractual employees) of IARC or retainers or any contractors, vendors, suppliers, or agencies that provide goods or services to IARC or entity engaged by IARC for services relating to acquisition or resolution of financial assets or customers of IARC or shareholders of IARC or any other person having an association with IARC (referred to as "complainant" later in this policy) may lodge a complaint/disclosure that is permitted to be made under this policy(hereinafter referred to as "Disclosure").
                        </p>
                        <p className='mb-5'>While making a Disclosure, the Complainant should act in good faith and should have direct
                            knowledge of the alleged irregularities or have reasonable grounds to believe that the
                            allegations contained in the Disclosure are substantially true. Any action to be contrary by
                            the Complainant will be viewed seriously and suitable action will be taken.
                        </p>
                        <p className='name mt-5 mb-5'>Prevention of misuse</p>
                        <p className='mb-5'>IARC recognizes the need to offer employees a safe and secure channel for filing Complaints.
                            Employees should note that serious action will be taken if it is found that there is an attempt
                            to misuse this channel.
                        </p>
                        <p className='mb-5'>While all steps will be taken by the Committee to maintain the confidentiality of each matter,
                            it is expected that the Complainant also maintains the same level of confidentiality.
                        </p>
                        <p className='mb-5'>The use of this channel is discouraged in any of the following scenarios which may be
                            considered as ‘Business as Usual’ issues and should be reported to the respective business
                            level authorities who are designated to address the same in each business by virtue of their
                            roles.
                        </p>
                        <ul className='list-decimal'>
                            <li className='mb-5'>
                                Malfunctioning of infrastructure, telecommunication systems and/or virtual
                                systems.
                            </li>
                            <li className='mb-5'>
                                Disagreements between employees arising in the normal course of discussion
                                relating to business /operational aspects.
                            </li>
                            <li className='mb-5'>
                                Disputes arising out of personal issues between employees
                            </li>
                            <li className='mb-5'>
                                Any action/issue which is currently under reasonable investigation and resolution
                                within a department as per the escalation matrix and resolution framework
                                followed by the business. An employee has a recourse to use this channel only if
                                reasonable time has passed without a resolution being arrived at as per normal
                                business matrix and/or if the resolution is biased and the employee has proof to
                                substantiate the same.
                            </li>
                        </ul>
                        <p className='name mt-5 mb-5'>How to report</p>
                        <p className='mb-5'>The Complainant can make a Disclosure to the Whistle-Blower and Vigil Committee
                            (hereinafter referred to as “Committee”) or any member thereof by sending an email to
                            Naina.kurane@iarc.co.in or by delivering physical copies of the relevant documents in a
                            sealed cover (either personally or by courier/post) to the following address:
                        </p>
                        <p className='mb-5'>A-601/602/605 6th Floor, 215 Atrium, Kanakia Spaces,
                            Andheri Kurla Road, Andheri (East),
                            Mumbai 400 093.
                        </p>
                        <p className='mb-5'>The Disclosure should be accompanied by:
                        </p>
                        <ul className='list-decimal'>
                            <li className='mb-5'>
                                A declaration that the Disclosure is made in good faith and that the Complainant
                                believes that the statements made therein are true and correct.
                            </li>
                            <li className='mb-5'>
                                A statement as to why the alleged matter is prejudicial to the interests of IARC.
                            </li>
                            <li className='mb-5'>
                                The available documentary evidence in support of the Disclosure.
                            </li>
                            <li className='mb-5'>
                                A confirmation that the Complainant will cooperate with any investigation in respect of the
                                Disclosure and provide further available information as and when called upon to do so.
                            </li>
                            <li className='mb-5'>
                                Contact information of the Complainant.
                            </li>
                        </ul>
                        <p className='name mt-5 mb-5'>Composition of the Committee</p>
                        <p >The Committee shall comprise of a group of senior management representatives from
                            different functional areas, as mentioned below:</p>
                        <div className='overflow-x-auto w-full max-h-[50rem] overflow-y-scroll mt-10'>
                            <table className='table mt-3 mb-3 border border-collapse'>
                                <thead>
                                    <tr>
                                        <th className='border p-2'>Committee composition</th>
                                        <th className='border p-2'>Incident Based Meetings <br /> Frequency</th>
                                        <th className='border p-2'>Turn Around Time (‘TAT’) for completing <br /> Investigation and Submission of report to the Audit Committee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='border p-2'>
                                            Member of Audit Committee (Chairman). <br /><br />
                                            Chief Finance Officer. <br /><br />
                                            Chief Compliance Officer. <br /><br />
                                            (External Consultants can be invited to the Committee from time to time for deliberations. However, they shall not form part of quorum and shall not have voting power).
                                        </td>
                                        <td className='border p-2'>
                                            Initial meeting should be held within 48 hours of receiving the Complaint. <br /><br />
                                            At least two Committee members should be present for each meeting. <br /><br />
                                            Decisions in meetings should be taken by majority vote.
                                        </td>
                                        <td className='border p-2'>
                                            60 days from the receipt of the Complaint. The Audit Committee may allow further time for submission of Report depending on the complexity and severity of the incident.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className='name mt-5 mb-5'>Process flow chart</p>
                        <ul className='list-decimal'>
                            <li className='mb-5'>
                                Initial meeting should be held within 48 hours of receiving the Complaint to discuss the further
                                course of action. The Committee Members shall promptly inform the Audit Committee of the
                                receipt of Disclosure and the action being taken.
                            </li>
                            <li className='mb-5'>
                                The Committee will examine the matter in detail and require the Chief Compliance Officer to
                                conduct an initial enquiry and submit a report within 7 working days.
                            </li>
                            <li className='mb-5'>
                                Second meeting should be held within 3 working days of completion of the aforesaid
                                timeframe to discuss the result of the initial enquiry, suggested remedial steps and
                                implementation thereof.
                            </li>
                            <li className='mb-5'>
                                If the Committee is of the view that further detailed investigation is necessary, the matter will
                                be referred to an external consultant (who has the requisite experience/expertise) and the
                                scope of work/terms of reference will be clearly specified. A timeframe will be fixed by the
                                Committee for completion of the said investigation, depending on the nature of the matter.
                            </li>
                            <li className='mb-5'>
                                The Committee shall submit a written report containing the findings of the investigation along
                                with its recommendations to the Audit Committee as soon as practically possible, but not
                                later than 60 days from the receipt of the Complaint. The Audit Committee may allow
                                additional time for report submission depending on the complexity and severity of the
                                incident
                            </li>
                            <li className='mb-5'>
                                If the Audit Committee finds on the basis of the investigation and the Committee report that
                                an improper, unethical or illegal act has been committed, it shall recommend to the Board of
                                Directors to take appropriate action as the Board may deem fit.
                            </li>
                            <li className='mb-5'>
                                Once the matter is closed, the documents will be kept in the safe custody of the HR
                                department.
                            </li>
                            <li className='mb-5'>
                                Matters involving allegations of sexual harassment will be forwarded to the relevant
                                committee constituted under the Sexual Harassment of Women at Workplace (Prevention,
                                Prohibition and Redressal) Act, 2013.
                            </li>
                            <li className='mb-5'>
                                The outcome of the enquiry/investigation will be communicated to the Complainant.
                            </li>
                        </ul>
                        <p className='name mt-5 mb-5'>Safeguards</p>
                        <div className='pointss mb-10'>
                            <ul className='list-decimal'>
                                <li className='mb-5'>
                                    The identity of the Complainant will be kept confidential and will not be disclosed, unless
                                    required under any provision of law or agreed to in writing by the Complainant or unless the
                                    Complainant has himself/herself disclosed the same to anyone.
                                </li>
                                <li className='mb-5'>
                                    The contents of any Disclosure and the consequent proceedings including any investigation
                                    shall be kept confidential, subject to the requirements of applicable law.
                                </li>
                                <li className='mb-5'>
                                    Bonafide use of discretion by employees of IARC will not be subjected to investigation under provisions of this policy. The decision of the Ombudsperson in the matter will be final.
                                </li>
                                <li className='mb-5'>
                                    The proceedings will be conducted without any presumption of guilt and in a transparent and objective manner.
                                </li>
                                <li className='mb-5'>
                                    No Complainant shall be victimized (or retaliated against) in any manner for the reason of
                                    having made a Disclosure. Any instance of such victimization/retaliation can be brought to
                                    the knowledge of the Committee, which will initiate remedial action as considered necessary.
                                </li>
                                <li className='mb-5'>
                                    Any person, who appears as a witness or otherwise assists an investigation relating to a
                                    Disclosure, shall be given the same degree of protection as the whistle blower.
                                </li>
                                <li className='mb-5'>
                                    If any member of the Committee has a conflict of interest with respect to any Complaint,
                                    he/she will not participate in any discussions relating to that Complaint and another senior
                                    level employee will be nominated by the Audit Committee to act in his/her place.
                                </li>
                                <li className='mb-5'>
                                    Disclosures from anonymous sources will not be entertained by the Committee. However, if
                                    an anonymous complaint/ disclosure provides credible and specific information or evidence
                                    that, in the considered view of the Committee, provides sufficient grounds for an
                                    investigation, the Committee may entertain the complaint and proceed in accordance with
                                    this Policy.
                                </li>
                                <li className='mb-5'>
                                    All concerned employees should co-operate with the investigation. Failure to do so could
                                    result in disciplinary action.
                                </li>
                                <li className='mb-5'>
                                    Whistleblowing under this Policy will not result in any immunity to the Complainant with
                                    respect to any complicity/involvement in the alleged irregularities.
                                </li>
                                <li className='mb-5'>
                                    The implementation of this Policy and all actions taken hereunder shall be in full compliance
                                    with all provisions of applicable law.
                                </li>
                            </ul>
                        </div>
                        <p className='name mb-10 mt-10'>
                            Review and Reporting
                        </p>
                        <div className='pointss mb-10'>
                            <ul className='list-decimal'>
                                <li className='mb-5'>
                                    The Audit Committee of the Board will oversee the implementation of this Policy and the functioning
                                    of the Committee. This Policy shall be placed for review by the Board every two years, or as may be
                                    required from time to time in view of statutory changes.
                                </li>
                                <li className='mb-5'>
                                    A report of Disclosures received, and the actions taken pursuant thereto, will be submitted by the
                                    Chief Compliance Officer to the Audit Committee and Board on a quarterly basis.
                                </li>
                            </ul>
                        </div>
                        <p className='name mb-5 mt-5'>
                            Compliance
                        </p>
                        <div className='pointss mb-10'>
                            <ul className='list-decimal'>
                                <li className='mb-5'>
                                    Compliance with this Policy is mandatory. Non-compliance by any employee shall attract disciplinary
                                    action, which may include termination of employment.
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </section>
        </>

    )
}

export default Whis
