import './App.css';


const Recovery = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>Recovery Agents</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20 min-h-screen flex flex-col justify-center'>
                    <p className='text-2xl'>
                        List of authorised recovery agency(ies)/agent(s), resolution agency(ies)/agent(s), collection agency(ies)/agent(s):
                    </p>
                    <div className='overflow-x-auto w-full max-h-[50rem] overflow-y-scroll mt-16'>
                        <table className="table-auto recovery-agent ">
                            <thead>
                                <tr>
                                    <th className='lg:min-w-[250px] '>Sr.No</th>
                                    <th className='min-w-[250px]'>Name of agency</th>
                                    <th className='min-w-[300px]'>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>A AND A ASSOCIATES</td>
                                    <td>DNK UMERKOTE NEAR HDFC BANK UMERKOTE</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>2</td>
                                    <td>A D J Consultants Private Limited</td>
                                    <td>334 A, SECOND FLOOR MAHINDRA PARK, RANI BAGH NEW DELHI 110034</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>AA ASSOCIATES & FINANCIAL</td>
                                    <td>DEOGHAR COURT ROAD JAMUNAJOR NEAR VIP CHOWK DEOGHAR 814112 JHARKHAND</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>4</td>
                                    <td>Aanchal Agency</td>
                                    <td>HOUSE NO 140 TARASAHED DAGICHA BEHIND RAJAPETH POLICE STATION BANDERA ROAD AMRAVATI 444606</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Aandavar Associates</td>
                                    <td>120, UPSTAIRS,THAMBI KALI AMMAN KOILS STREET, PONNAMMAPET,SALEM-636001,TAMILNADU</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>6</td>
                                    <td>Aastha Collection</td>
                                    <td>SHOP NO.20,TULSI COMPLEX,BH RAILWAY STATION,NADIAD,387002</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Abhi Associates</td>
                                    <td>ICICI BANK LTD., SHOP NO. 17,18,18A 19, LEKHRAJ KHAJANA COMPLEX, PLOT NO. D 2, SECTOR 4, INDIRA NAGAR, FAIZABAD ROAD, LUCKNOW, UTTAR PRADESH.226016</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>8</td>
                                    <td>ABHIKSHA ASSOCIATES</td>
                                    <td>HATIGAON CHARIALI, NEAR HIGH SCHOOL, BHISHNOJYOTI PATH, PIN-781038</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>ABHINAV ENTERPRISES</td>
                                    <td>GROUND FLOOR, AMG PLAZA, GHMC NO. 10-2-277/A, ENTRENCHMENT ROAD,WEST MARREDPALLY, SECUNDERABAD(HYDERABAD),TELANGANA- PIN CODE 500026, TOLL FREE NO. 18004194332</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>10</td>
                                    <td>Accurate Investigations</td>
                                    <td>A-5 BASEMENT FLOOR SHANKAR GARDEN VIKAS PURI WEST DELHI 110018</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>ACHIEVERS ASSOCIATE</td>
                                    <td>12-13-677\77 STREET NO 1, KIMTEE COLONY, TARNAKA, SECUNDERABAD TS-500017</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>12</td>
                                    <td>Agile Trading</td>
                                    <td>HOUSE NO -96 1ST FLOOR DILIP HUJURI PATH,SARUMOTORIA. GUWAHATI 781005</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Akriti enterprises</td>
                                    <td>KAILASH PARK, MANORAMAGANJ,INDORE, MADHYA PRADESH,PIN - 452 001.</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>14</td>
                                    <td>All Set</td>
                                    <td>ICICI BANK LTD., NO. 1, CENOTAPH ROAD, CHENNAI, TAMIL NADU.600018</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>AMBIKA ENTERPRISES</td>
                                    <td>11/3,MURATMOHALLA CHHAWANJ INDORE MADHYAPRADESH 452001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>16</td>
                                    <td>AMBITIOUS ENTERPRISE</td>
                                    <td>VILL- PORSA, P.0+P.S- ITAHAR, DIST- UTTAR DINAJPUR, PIN- 733128</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>Anu Management And E-Services</td>
                                    <td>114 MAHATMA GANDHI ROAD, BANGALORE 560001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>18</td>
                                    <td>Arora Associate</td>
                                    <td>FLAT NO1 SANT ICHAR,SINGH NAGAR PINK FLATS PAKHUWALROAD LUDHIANA PUNJAB 141001</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>ARYAN ASSOCIATES</td>
                                    <td>BIJAWAR NAKA,SAGAR ROAD CHHATARPUR MADHYA PRADESH 471001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>20</td>
                                    <td>AS ASSOCIATES</td>
                                    <td>NO 251, 2ND FLOOR APARNA BUILDING, 17TH CROSS, SAMPIGE ROAD, MALLESHWARAM, BANGALORE - 560003</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>B.k Associates</td>
                                    <td>NEAR PRIMARY SCHOOL MIG -2/25 ASHIYANA PHASE 2 MORADABAD UTTARPRADESH 244001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>22</td>
                                    <td>B.M Credit Solution</td>
                                    <td>NANGAL, RAYA BRANCH, DELHI</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>BAGATH</td>
                                    <td>LIBERLY COMPLEX OPP BIJAPUR HOSPITAL ARVIND NAGAR KARWAR KARNATAKA 580024</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>24</td>
                                    <td>Bankey Bihari Associate</td>
                                    <td>VASUNDHARA COLONY, OPP. SURAJ DHARAM KANTA MATHURA ROAD, SASNI GATE ALIGARH- 202001</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>Besteam Energy</td>
                                    <td>SCO 380 FF SECTOR 37 D CHANDIGARH, CHANDIGARH, 160036.</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>26</td>
                                    <td>BHA Financial solution</td>
                                    <td>24 GURUKUL COLONY RANGWAS SQUARE CAT ROAD RAU INDORE 453331</td>
                                </tr>
                                <tr>
                                    <td>27</td>
                                    <td>BHAGWATI CREDIT SOLUTIONS</td>
                                    <td>A-44 IIND FLOOR, AMAR COMPLEX, BHAGWATI GARDEN UTTAM NAGAR NEW DELHI-110059</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>28</td>
                                    <td>Bhatiya Associates</td>
                                    <td>BHATIYA ASSOCIATES..SHOP NO.G-25,322 SAMRIDDHI COMPELAX NEAR TOWER SQUARE INDORE..PIN CODE..452001</td>
                                </tr>
                                <tr>
                                    <td>29</td>
                                    <td>BHUPINDER ASSOCIATES</td>
                                    <td>PLOT B19 65 SE THE MALL LGF 1 1ST MALL LUDHIANA PUNJAB 141001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>30</td>
                                    <td>BIGBULL MANAGEMENT SERVICES</td>
                                    <td>JADHUA MARKET MAHADEV COMPLEX JADHUA HAJIPUR VAISHALI BIHAR 844101</td>
                                </tr>
                                <tr>
                                    <td>31</td>
                                    <td>Bm Credit Solutions Llp</td>
                                    <td>NANGAL, RAYA BRANCH, DELHI</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>32</td>
                                    <td>Bright Fino Tech Services LLP</td>
                                    <td>SNO-25/4A/4, NAVECHA ROAD, PIMPLE GAURAV ROAD, SHREE SAINATH NAGAR, PIMPLE GURAV, PIMPRI CHINCHWAD, PUNE, MAHARASHTRA, 411061</td>
                                </tr>
                                <tr>
                                    <td>33</td>
                                    <td>CEDAR BUSINESS SOLUTION</td>
                                    <td>NO.7/3,2ND FLOOR,SUBBARAYA STREET,SHENOY NAGAR,CHENNAI-600030</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>34</td>
                                    <td>CHOUDHARY CONSULTANCY</td>
                                    <td>GROUND FLORE, TANWAR PALAZA,OLD DELHI ROAD,NEAR OBC BANK, SHAHAPUR,JAIPUR,RAJ 303103</td>
                                </tr>
                                <tr>
                                    <td>35</td>
                                    <td>Citi Enterprises</td>
                                    <td>SECTOR -40, CHANDIGARH - 160036</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>36</td>
                                    <td>Collekto</td>
                                    <td>9TH FLOOR . UNIT 905, FENKIN 9, NEAR SATKAR GRAND HOTEL, WAGLE ESTATE, THANE : 400604</td>
                                </tr>
                                <tr>
                                    <td>37</td>
                                    <td>Credexa Business Solutions Private limited</td>
                                    <td>OFFICE NO.403, RUPA SOLITIARE, BLDG. A-1, MILLENNIUM BUSINESS PARK, SECTOR-2, MAHAPE, NAVI MUMBAI- 400710</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>38</td>
                                    <td>Credit Revive Financial Services</td>
                                    <td>1114/A,ROYAL COURTS,OFFICE NO.O-16,SHAHUPURI,WARD,KOLHAPUR 416001</td>
                                </tr>
                                <tr>
                                    <td>39</td>
                                    <td>CREDIT SOLUTIONS</td>
                                    <td>PANIHATI MUNICIPALITY,B. T. ROAD, PANIHATI NORTH 24 PARGANAS</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>40</td>
                                    <td>Credit Spread Management</td>
                                    <td>196/7CBQ,STREET NO 7.THAN SINGH NAGAR,ANAND PARBAT NEW DELHI 110005</td>
                                </tr>
                                <tr>
                                    <td>41</td>
                                    <td>CSR ASSOCIATES</td>
                                    <td>#67, 1ST FLOOR, 3RD MAIN ROAD, 5TH CROSS, CHAMRAJPET BANGALORE 560018</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>42</td>
                                    <td>D FOCUS</td>
                                    <td>FLAT NO: 375, KURUNJI NAGAR, SATHUVACHARI, VELLORE-632009</td>
                                </tr>
                                <tr>
                                    <td>43</td>
                                    <td>DABLA BROTHERS PVT. LTD.</td>
                                    <td>B-26 27, MAHALAXMI NAGAR,JLN MARG, JAIPUR-302017</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>44</td>
                                    <td>Daksha Services</td>
                                    <td>1866 E WARD,RAJARAMPURI,7TH LANE,KOLHAPUR-416008</td>
                                </tr>
                                <tr>
                                    <td>45</td>
                                    <td>DEBT CARE ENTERPRISES PVT LTD</td>
                                    <td>104 B SNEHAPRABHA APARTMENTS NEW BOWENPALLY SECUNDERABAD 500011</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>46</td>
                                    <td>DHAATA FORCE</td>
                                    <td>OFFICE NO 64 VALLUVAR SALAI ARUMBAKKAM CHENNAI TAMIL NADU 600106</td>
                                </tr>
                                <tr>
                                    <td>47</td>
                                    <td>Divine Associates</td>
                                    <td>ICICI BANK LTD., SCF 19, PHASE 1, URBAN ESTATE, DUGRI, LUDHIANA, LUDHIANA DIST., PUNJAB.141013</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>48</td>
                                    <td>DURGA ASSOCIATES</td>
                                    <td>H.NO:20-11/2,PLOT NO:315/B,GOUTHAM NAGAR,MALKAJGIRI,HYDERABAD - 500047</td>
                                </tr>
                                <tr>
                                    <td>49</td>
                                    <td>DVB ASSOCIATES</td>
                                    <td>18-434/1,PLOT NO 112,MALLIKARJUNA NAGAR, MALKAJGIRI,SECUNDERABAD,TS-500047</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>50</td>
                                    <td>Eagle Services</td>
                                    <td>402, SAFFRON COMPLEX FATEHGUNJ CIRCLE FATEHGUNJ MAIN ROAD VADODARA 390002</td>
                                </tr>
                                <tr>
                                    <td>51</td>
                                    <td>EARTH Services</td>
                                    <td>ICICI BANK LTD., 20.A, SHIVAJI MARG, MOTI NAGAR, NEW DELHI.110015</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>52</td>
                                    <td>EERA ASSOCIATES</td>
                                    <td>NARMADAPURAM BRANCH, GROUND FLOOR, ANAND NAGAR</td>
                                </tr>
                                <tr>
                                    <td>53</td>
                                    <td>EMERALD ASSOCIATES</td>
                                    <td>NO 14/17 SOWRASHTRA NAGAR 1ST STREET CHOOLAIMEDU NUNGAMBAKKAM CHENNAI 600094</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>54</td>
                                    <td>Excello Hub Services</td>
                                    <td>OFFICE NU.403,4TH FLOOR FENKIN EMPIRE NEAR SHIV SAGAR HOTEL THANE STATION THANE WEST 400601</td>
                                </tr>
                                <tr>
                                    <td>55</td>
                                    <td>EXON TECHNOLOGIES</td>
                                    <td>NO-7A, NEW NO-12/1, JHANSI PLAZA, 2ND FLOOR, NEW AVADI ROAD, VILLIVAKKAM,CHENNAI-600049.</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>56</td>
                                    <td>FINANCIAL SOLUTIONS</td>
                                    <td>KHASRA NO.936, AL-REHMAN SHOPPING COMPLEX, HARI SINGH HIGH STREET, SRINAGAR SRINAGAR JAMMU AND KASHMIR 190001</td>
                                </tr>
                                <tr>
                                    <td>57</td>
                                    <td>FORTE MANAGEMENT SERVICES</td>
                                    <td>BALI TOWERS NO 1 ABDUL RAZACK STREET, SAIDAPET, CHENNAI-600015</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>58</td>
                                    <td>FRIENDS ASSOCIATES</td>
                                    <td>NAINAKSH TOWER FIRST FLOOR OFFICE NO FF13 MANDIDEEP RAISEN 462046</td>
                                </tr>
                                <tr>
                                    <td>59</td>
                                    <td>GALAXY ASSETS CONSULTANCY</td>
                                    <td>01, BARBARIA, BARRACKPORE BARASAT ROAD, BARBARIA BUS STAND, DUTTAPUKUR, BARASAT, NORTH TWENTY FOUR PARGANAS, WEST BENGAL, 700126</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>60</td>
                                    <td>GANASRI ENTERPRISES</td>
                                    <td>#27, H R RESIDENCY, JANATHA COLONY, JAKKASANDRA 1ST MAIN ROAD, KORAMANGALA 1ST BLOCK, BANGALORE-560034.</td>
                                </tr>
                                <tr>
                                    <td>61</td>
                                    <td>Gargi Enterprises</td>
                                    <td>SNO 156/10/MBP CHAMPBERS 4TH FLOOR OPP VAIBHAV CINEMA NEAR SAVALI CORNER HOTEL HADAPSAR PUNE 411028 MAHARASHATRA</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>62</td>
                                    <td>GMR ENTERPRISES</td>
                                    <td>8-3-214/53/A/1,2 ND FLOOR,SRINIVASACOLONY WEST,AMEERPET,HYD-500038</td>
                                </tr>
                                <tr>
                                    <td>63</td>
                                    <td>GS Services</td>
                                    <td>NEW MAHAVEER COLONY TETRI TOLI NAMKUM RANCHI JHARKHAND 834010</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>64</td>
                                    <td>Harishitha</td>
                                    <td>42,5TH CROSS 5TH MAIN JAYANAGAE MYSORE 570014</td>
                                </tr>
                                <tr>
                                    <td>65</td>
                                    <td>HARSHA CONSULTANTS</td>
                                    <td># 213 ASHIRWADA NILAYA 2ND MAIN TEACHERS LAYOUT NAGARBHAVI 1ST STAGE BANGLORE-560072</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>66</td>
                                    <td>HARSHIVI ENTERPRISES</td>
                                    <td>NEAR HOLI CHOWK UPPER G/F A-47 HARI VIHAR UTTAM NAGAR HASTSAL VIHAR NEW DELHI 110059</td>
                                </tr>
                                <tr>
                                    <td>67</td>
                                    <td>HIRANYA CAPITALS</td>
                                    <td>PLOT NO3681, 2ND FLOOR,TNHB MAIN ROAD,KAKKALUR,THIRUVALLUR -602001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>68</td>
                                    <td>Hi-Tek Syndicate</td>
                                    <td>J-3VIKASPURI NEW DELHI PHONE 28542393,28542404</td>
                                </tr>
                                <tr>
                                    <td>69</td>
                                    <td>HOPES FIN CON</td>
                                    <td>3RD FLOOR REGENT COURT IYYATTILMUKKU CHITTOR ROAD KOCHI KERALA 682011</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>70</td>
                                    <td>HR FINANCIAL SERVICES</td>
                                    <td>202-C FLOOR KANCHAN SAGAR OLD PLASIA AB ROAD INDORE 452001</td>
                                </tr>
                                <tr>
                                    <td>71</td>
                                    <td>ICREDIZEN FINANCIAL TECHNOLOGIES PRIVATE LTD</td>
                                    <td>DR NO.2-64/2/A,PLOT NO.20 & 21 FOURTH FLOOR,RAGHU TOWERS MEGHA HILLS ROAD 2 MADHAPUR HYDERABAD TELANGANA INDIA 500081</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>72</td>
                                    <td>IG TECHSERV PVT LTD</td>
                                    <td>B-5,6,7, H-73, SEC-63, NOIDA U.P. IN</td>
                                </tr>
                                <tr>
                                    <td>73</td>
                                    <td>Indus Financial Services</td>
                                    <td>2ND FLOOR, RZ 421, GALI NO 13, TUGHLKABAD EXTN, SOUTH EAST DELHI, DELHI, 110019.</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>74</td>
                                    <td>INTERNAL POWER SERVICES</td>
                                    <td>SAI NATH NAGAR H-104 C -SECTOR BHOPAL</td>
                                </tr>
                                <tr>
                                    <td>75</td>
                                    <td>JAI MAA SWAROOP ENTERPRISES</td>
                                    <td>12-B JAI KARNI NAGAR NIWARU ROAD ,NR. AMBEY HOSPITAL NIWARU ROAD JHOTWARA ,JAIPUR- 302012</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>76</td>
                                    <td>JAI MATA DI ENTERPRISES</td>
                                    <td>SHOP 2/10/3, MAHAK APPARTMENT, KUSHTADHAM ROAD, BHISTABAG, SAWEDI, AHMEDNAGAR - 414003</td>
                                </tr>
                                <tr>
                                    <td>77</td>
                                    <td>Jai Shree Maruti Recovery</td>
                                    <td>PLOT NO 3 SECROR NO 2 ARIHANT PARISAR RATLAM 457001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>78</td>
                                    <td>Jay Mataji Enterprises</td>
                                    <td>201, AASMAN COMPLEX, NR SAMRUDH DAIRY, B/H NAVRANGPURA POST OFFICE, NAVRANGPURA, AHMEDABAD - 380009</td>
                                </tr>
                                <tr>
                                    <td>79</td>
                                    <td>Jbms Associates</td>
                                    <td>350/351, POCKET -1 SEC A-06, DDA FLAT, SECOND FLOOR. JAGRITI APARTMENT, NARELA DELHI 110040.</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>80</td>
                                    <td>Jobel associate</td>
                                    <td>PLOT NO -608/609,SATYA NAGAR KALIMANDIR ROAD BHUBANESWAR ODISHA 751007</td>
                                </tr>
                                <tr>
                                    <td>81</td>
                                    <td>JS Associates</td>
                                    <td>GALA NO 1 KRISHNA KUNJ BUILDING BELOW BANK OF BARODA NEAR RAVINDRA HOTEL DAHISAR EAST MUMBAI 400068</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>82</td>
                                    <td>KAKSHI ASSOCIATES</td>
                                    <td>MDR ROAD RAIL WAY GATE NO 2 NEW GUWAHATI,NOONMATI GUWAHATI-781020( ASSAM)</td>
                                </tr>
                                <tr>
                                    <td>83</td>
                                    <td>kanha enterprise</td>
                                    <td>5TH FLOOR,516/BAKOR PATEL CHAMBER NEAR KARELIBUAG POLICE STATION VADODARA 390018</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>84</td>
                                    <td>Kavish Enterprises</td>
                                    <td>4/221 NEAR HOTEL ABHAY HAVELI CHITRAKOAT VAISHALI NAGAR JAIPUR RAJSTHAN 302021</td>
                                </tr>
                                <tr>
                                    <td>85</td>
                                    <td>Keerthi Assocaites</td>
                                    <td>T6-C 18,3RD FLOOR,SRIRAM TOWERS,3RD CROSS,WEST THILLAI NAGAR TRICHY 620018</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>86</td>
                                    <td>KHAN INTERPRISES AND RECOVERY</td>
                                    <td>WARD NO2 BEHIND KURWAI POST OFFICE VIDISHA MP 464224</td>
                                </tr>
                                <tr>
                                    <td>87</td>
                                    <td>KHYATI ASSOCIATES</td>
                                    <td>WARD NO. 14, PATANDEV SAGAR ROAD, RAISEN, [M.P.] PIN CODE: 464551</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>88</td>
                                    <td>KING ENTERPRISES REPO AND RECOVERY AGENCY</td>
                                    <td>BEHIND PUNJAB NATIONAL BANK LINK ROAD SIRONJ 464228</td>
                                </tr>
                                <tr>
                                    <td>89</td>
                                    <td>Krishiv Collection Agency</td>
                                    <td>SHOP NO 21 MARRY GOLD COMPLEX NEAR ICICIBANK MOTIBAUG JUNAGADH 362001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>90</td>
                                    <td>Krishna Services</td>
                                    <td>3 OPPROSITE CSD CANTEEN ALWAR ROAD BEHROR ALWAR 301701</td>
                                </tr>
                                <tr>
                                    <td>91</td>
                                    <td>KS VENTURE</td>
                                    <td>HOUSE NO 2 JONALI PATH AMBIKA GIRI NAGAR GUWAHATI 781024</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>92</td>
                                    <td>KUSUM ENTERPRISE</td>
                                    <td>ARUN GOLAP BHAWAN,HOUSE NO 8,3RD FLOOR,NILOMONI PHUKAN PATH,CHRISTIAN BASTI GS ROAD GUWAHATI 781005</td>
                                </tr>
                                <tr>
                                    <td>93</td>
                                    <td>Lane Consultancy</td>
                                    <td>NO-67, 2ND FLOOR, MADHAVARAM HIGH ROAD, PERAMBUR, CHENNAI, TAMIL NADU 600011</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>94</td>
                                    <td>LK Associates</td>
                                    <td>NO.53/1, PLOT NO.21, THIRUKKAI AVENUE STREET, KALIAMMAN KOIL STREET, VIRUGAMBAKKAM, CHENNAI – 600 092</td>
                                </tr>
                                <tr>
                                    <td>95</td>
                                    <td>M.H.COLLECTIONS</td>
                                    <td>APEEJAY HOUSE,15, PARK STREET,KOLKATA - 700 016</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>96</td>
                                    <td>M.k Associates</td>
                                    <td>PLOT NO 3 OFFICE NO 3 2ND FLOOR RAMTERAM PLAZZA BUILDING SECTOR 23 SANJAY NAGAR GZB UP 201001</td>
                                </tr>
                                <tr>
                                    <td>97</td>
                                    <td>Maa Bhawani Enterpises</td>
                                    <td>PLOT NO 233, DAATA SADAN OPPOSITE RAJASTHAN PATRIKA OFFICE, MAN JI KA HATHA PAOTA B ROAD, JODHPUR, RAJASTHAN-342001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>98</td>
                                    <td>Maa Pratap Services</td>
                                    <td>123-124-125, MADHAV HILL, NR. TAKHTESHVAR TEMPLE, WAGHAWADI ROAD, BHAVNAGAR</td>
                                </tr>
                                <tr>
                                    <td>99</td>
                                    <td>MAA SHARDAKRIPA ASSOCIATES</td>
                                    <td>1ST FLOOR, GARDEN HOMES,BEHIND SUNIL HOSIERY,NEPIER TOWN,NEAR BHAWARTAL GARDEN JABALPUR 482001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>100</td>
                                    <td>MAHAVEER CONSULTANCY</td>
                                    <td>PLOT NO-1123/3867,RABINDRA GARDEN,SOUBHAGYA NAGAR,BARAMUNDA,BHUBANESWAR 751001</td>
                                </tr>
                                <tr>
                                    <td>101</td>
                                    <td>MAHI ASSOCIATES</td>
                                    <td>A 301 INDRIA CHAMBERS, SADASHIV PETH, TILAK ROAD, PUNE 41130</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>102</td>
                                    <td>Mangalam Associates</td>
                                    <td>MANGLAM ASSOCIATES..204,WEST END CORPORATE BUILDING NEW PALASIYA INDORE..PIN CODE..452010</td>
                                </tr>
                                <tr>
                                    <td>103</td>
                                    <td>Mark Enterprises</td>
                                    <td>705 /709 7TH FLOOR TOPIWALA CENTER GOREGAON (W)</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>104</td>
                                    <td>MARUTI ASSOCIATES</td>
                                    <td>JAPORIGOG, KARBI NAMGHAR PATH, H NO.57, GUWAHATI-781005, ASSAM</td>
                                </tr>
                                <tr>
                                    <td>105</td>
                                    <td>Maruti Nandan</td>
                                    <td>S/61 2ND FLOOR, AMEE ARCADE, OPP NEW BUS STAND, GODHRA</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>106</td>
                                    <td>MAX FINANCIAL SERVICES</td>
                                    <td>C-109,11 B CROSS,WEST THILLAI NAGAR,TRICHY-620018</td>
                                </tr>
                                <tr>
                                    <td>107</td>
                                    <td>MP Associates</td>
                                    <td>JYOTHIRAJ M 152/439 2ND MAIN ROAD NEW BALAJI LAYOUT DODDNAGMANGALA ELECTRONICITY PHASE 2 BANGLORE 560100</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>108</td>
                                    <td>MSwipe Capital Pvt Ltd</td>
                                    <td>A 2602,26TH FLOOR MARATHON FUTUREX ,MAFATLAL MILLS COMPOUND N.M.JOSHI MARG LOWER PAREL MUMBAI CITY MH 400013</td>
                                </tr>
                                <tr>
                                    <td>109</td>
                                    <td>NANDA FINANCIAL SERVISES</td>
                                    <td>H.NO 336,ROHTA ROAD,ANOOP NAGAR FAZALPUR, MEERUT</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>110</td>
                                    <td>Natraj Motors</td>
                                    <td>OFF NO 11, PRAKASH SHETH CHAWL,NR. RAM GOPAL IND, V. S. AGASHE ROAD,DADAR (W) MUMBAI 400 028.</td>
                                </tr>
                                <tr>
                                    <td>111</td>
                                    <td>NAVRAJ COMMUNICATION SERVICES LLP</td>
                                    <td>3RD FLOOR,SR NO 141/3/2/6,OFFICE NO 304 SNEHA CONSUTRUCTION BUILDING NDA ROAD NEAR WARJE BRIDGE WARJE MALWADI PUNE MAHARASHATRA 411058</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>112</td>
                                    <td>NEERAJ ASSOCIATES</td>
                                    <td>42,43 SUMER ENCLAVE BUS STAND WATIKA, SANGANER JAIPUR RAJASTHAN 303905</td>
                                </tr>
                                <tr>
                                    <td>113</td>
                                    <td>NN ASSOCIATES</td>
                                    <td>#2773, SANTHE MAIDHANA KOLAR MAIN ROAD BANGARPET-563114</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>114</td>
                                    <td>OASIS CREDIT MANAGEMENT</td>
                                    <td>NO.3 VILLAGE ROAD NUNGAMBAKKAM, 'CHENNAI - 600 034</td>
                                </tr>
                                <tr>
                                    <td>115</td>
                                    <td>OK.Credit collection</td>
                                    <td>NEAR ICON PLAZA GOBARSAHI MUZZAFFARPUR BIHAR 842001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>116</td>
                                    <td>Om sai Services</td>
                                    <td>4TH FLOOR, BLOCK 554, NR.AGARWAL CHAMBER, OPP.TOWN HALL, ELLIS BRIDGE,</td>
                                </tr>
                                <tr>
                                    <td>117</td>
                                    <td>OM SHIV ASSOCIATES</td>
                                    <td>PATWARI COLONY PURANA MAHINDRA SHOWROOM KE UPPER BABAI ROAD NARMADAPURAM HOSANGABAD MP 461001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>118</td>
                                    <td>PATIL ENTERPRISES</td>
                                    <td>PALAM BRANCH, DIST PRABHANI</td>
                                </tr>
                                <tr>
                                    <td>119</td>
                                    <td>Pinnacle advisory</td>
                                    <td>PLOT NO 05 KURUD ROAD INFRONT OF MANSA COLLAGE BHILAI DIST-DURG CHATTISGARH 490023</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>120</td>
                                    <td>Poniya Services</td>
                                    <td>E-585, PARSHWANATH TOWN SHIP, PART-02, NR. SHIVJI CHOWK, KRISHNA NAGAR, NEW NARODA, AHMEDABAD, GUJARAT -382345</td>
                                </tr>
                                <tr>
                                    <td>121</td>
                                    <td>POONIA ASSOCIATES PVT LTD</td>
                                    <td>KOTAK MAHINDRA BANK LTD GROUND FLOOR, PLOT NO. 406 TO 408/28 ROOPALAY COMPLEX, MODEL TOWN ROHTAK, HARIYANA-124001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>122</td>
                                    <td>PR chaudhary</td>
                                    <td>F-26 BALAJI COMPLEX OPP HIMALAYA MALL MEHSANA 384002 GUJRAT</td>
                                </tr>
                                <tr>
                                    <td>123</td>
                                    <td>Prachi Associates</td>
                                    <td>BYPASS ASHTA NEAR DCB BANK ASHTA SEHORE,MADHYAPRADESH</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>124</td>
                                    <td>PRANAVAM CREDIT MANAGEMENT PVT LTD.</td>
                                    <td>DOOR NO.1/3714C1, 2ND FLOOR, CITY CORNER BUILDING NADAKKAVU, CALICUT 673011</td>
                                </tr>
                                <tr>
                                    <td>125</td>
                                    <td>Probiz Recovery Assocites Private Limited</td>
                                    <td>NFRONT OF MA PURNAGIRI GUEST HOUSE SHRINGAR NAGAR, NIDHAULI ROAD, ETAH- UP 207001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>126</td>
                                    <td>Progresseeve feentech</td>
                                    <td>A 1 NEW INDIRA CO-OP SOCIETY NEAR UNION BANK OF INDIA SHASTRI NAGAR YERAWADA PUNE 411006</td>
                                </tr>
                                <tr>
                                    <td>127</td>
                                    <td>R J ASSOCIATES</td>
                                    <td>NO.7, RATHINA NAGAR ,GUNDUSALAI ROAD ,MANJAKUPPAM .CUDDALORE - 607001.LANDMARK : NEW COLLECTOR OFFICE.</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>128</td>
                                    <td>R M ASSOCIATES</td>
                                    <td>16-11-511/D/21/4, SHALIVAHANA NAGAR DILSUKHNAGAR HYDERABAD-500036</td>
                                </tr>
                                <tr>
                                    <td>129</td>
                                    <td>RAAMDHENU ENTERPRISE</td>
                                    <td>BOIRAGIMOTH KOCHARI GOAN DIBRUGARH ASSAM 786003</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>130</td>
                                    <td>RANA CORPORATION</td>
                                    <td>113,114 HOTEL SATKAR BUILDING UNAPANI ROAD DELHIGATE SURAT-395003</td>
                                </tr>
                                <tr>
                                    <td>131</td>
                                    <td>Ratna agency</td>
                                    <td>305 /RUDRAMAL COMPLEX OPP AMOLA PG HOUSE NEAR GANESH PLAZA NAVA RANPURA POST OFFICE AHMEDABAD 380009</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>132</td>
                                    <td>Ridhi Sidhi Associates</td>
                                    <td>G2/H-125,MANGALAM CITY VISTAR H-BLOCK KALWARROAD JHATWARA JAIPUR RAJSTHAN 302012</td>
                                </tr>
                                <tr>
                                    <td>133</td>
                                    <td>RM Associates</td>
                                    <td>16-11-511/D/21/4, SHALIVAHANA NAGAR DILSUKHNAGAR HYDERABAD-500036</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>134</td>
                                    <td>RM SERVICE</td>
                                    <td>PLOT NO 3214,ROOM NO 103 1 ST FLOOR ,MARUTI BHABAN,KOTITIRTHA LANE OLD TOWN BHUBANESWAR KHORDHA PIN 751002</td>
                                </tr>
                                <tr>
                                    <td>135</td>
                                    <td>Romit akaay Associates</td>
                                    <td>OFFICE NO. 503 5TH FLOOR 78 BUSINESS PARK (BMC MARKET BLDG) STATION ROAD BHANDUP WEST MUMBAI 400078</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>136</td>
                                    <td>Roshan Management</td>
                                    <td>3B1 SOLAI COMPLEX 1ST FLOOR MAHAVEER GARDEN ANUSHA STREET AMBATTUR CHENNAI 600058</td>
                                </tr>
                                <tr>
                                    <td>137</td>
                                    <td>Royal Baba Dabas</td>
                                    <td>PLOT NO C1-48-49, UPPER GROUND FLOOR, FLAT NO.001, SURAJ TOWER, DEEP VIHAR, OPP SEC 24, ROHINI, DELHI – 110085</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>138</td>
                                    <td>RR Enterprises</td>
                                    <td>SECOND FLOOR, SCO NO. 90, R R ENTERPRISES, SECTOR 24 C,CHANDIGARH, CHANDIGARH, CHANDIGARH, 160036</td>
                                </tr>
                                <tr>
                                    <td>139</td>
                                    <td>Rudra Finance</td>
                                    <td>PIMPRI CHOWK, PUNE 411017</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>140</td>
                                    <td>RUDRA FINANCIAL MANAGEMENT</td>
                                    <td>SNO 213,HISSA NO.02 FLAT NO 203 ,2ND FLOOR C WING SHANKAR SUMAN PARK BHEKRAINAGAR GURUDATTA COLONY NEAR SHIVSHAKTI CHOWK ,PO-PHURSUNGI CITY HADAPSAR PUNE 412308</td>
                                </tr>
                                <tr>
                                    <td>141</td>
                                    <td>Rudraksh Associates</td>
                                    <td>N3/75-B-6 R K PURAM COLONY KARMAJEETPUR SUNDERPUR VARANASI 221005 UP</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>142</td>
                                    <td>S A Management Services</td>
                                    <td>815/B WING DHAMJI SHAMJI CORPORATE SQUARE LAXMI NAGAR GHARKOPAR EAST 400075 MAHARASHATRA</td>
                                </tr>
                                <tr>
                                    <td>143</td>
                                    <td>S D ENTERPRISES</td>
                                    <td>GR FLOOR AND BASEMENT SCO NO 381 SECTOR 37 D CHANDIGARH 160036</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>144</td>
                                    <td>SA CORPORATE</td>
                                    <td>BEHALA, 7 BRAMHASAMAJ ROAD 700034</td>
                                </tr>
                                <tr>
                                    <td>145</td>
                                    <td>SADASHIV ASSOCIATES</td>
                                    <td>CHOUDHARY COMPLEX, RAJENDRA CHOWK, BIHAR</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>146</td>
                                    <td>SAHOO SOLUTION</td>
                                    <td>PLOT NO 1559/359 SATYA VIHAR MANCHESWAR BHUBANESWAR ODISHA 751010</td>
                                </tr>
                                <tr>
                                    <td>147</td>
                                    <td>SAI CHERISH FINANCIAL SERVICES</td>
                                    <td>GROUND FLOOR ,PLOT BEARING NO-83 AND 84,PUNNAIAH PLAZA, ROAD NO 2 BANJARA HILLS, NEAR JUBILEE HILLS CHECK POST,HYDERABAD,TELANGANA STATE, PIN CODE-500 033</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>148</td>
                                    <td>SAI KRIPA ASSOCIATES</td>
                                    <td>PLOT NO 228 MCZZANINE FLOOR MP NAGAR ZONE 1 BHOPAL MADHYAPRADESH 462011</td>
                                </tr>
                                <tr>
                                    <td>149</td>
                                    <td>SATYAM AGENCY</td>
                                    <td>U-6 KUBER COMPEX NEAR KOTAK MAHINDRA BANK DR YAGNIK ROAD RAJKOT GUJRAT 360001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>150</td>
                                    <td>SHANMUGAM BIKERS</td>
                                    <td>3/159 SUBRAYAN STREET ,VALLULAR NAGAR, MADAMBAKKAM, GUDUVANCHERY -603202</td>
                                </tr>
                                <tr>
                                    <td>151</td>
                                    <td>SHARMISHTA ENTERPRISES</td>
                                    <td>LIG 285 A SECTOR SONAGIRI INFRONT DURGA MANDIR PIPLANI BHOPAL 462022</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>152</td>
                                    <td>SHARP MANAGEMENT SERVICES</td>
                                    <td>602 RAJGOR EMPIRE KHOT LANE GHATKOPAR WEST MUMBAI 400086</td>
                                </tr>
                                <tr>
                                    <td>153</td>
                                    <td>Shepherd Financial Services Pvt Ltd.</td>
                                    <td>202 INDIRA ARCADE BEHIND HOTEL PLAZA SADAR NAGPUR 440001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>154</td>
                                    <td>Shiv Associates</td>
                                    <td>53/4-A, BHAG KAMBAL WALA,JANSATH ROAD,NEW MANDIMUZZAFAR NAGAR UTTAR PRADESH 251001</td>
                                </tr>
                                <tr>
                                    <td>155</td>
                                    <td>Shiv Shakti Associates</td>
                                    <td>1/56 D TOP FLOOR, LALITA PARK, NEAR BY GURUDWARA LAXMI NAGAR EAST DELHI-110092</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>156</td>
                                    <td>SHREE BALAJI MANAGEMENT</td>
                                    <td>(GC 98 1ST FLOOR PULL PRALHADPUR NEAR RAM CHANDER MEMORIAL SCHOOL NEW DELHI110044</td>
                                </tr>
                                <tr>
                                    <td>157</td>
                                    <td>SHREE GANESH AGENCY</td>
                                    <td>FLAT NO 202 2ND FLOOR PARISHRAM APPARTMENT KUKDE LAYOUT RAMESHWARI ROAD NEAR CHANDRAMANI NAGAR N I T GARDEN BABULKHEDA NAGPUR 4400277</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>158</td>
                                    <td>Shree Mahadevi Associates</td>
                                    <td>AC-167C, SHALIMAR BAGH, NEW DELHI-110088</td>
                                </tr>
                                <tr>
                                    <td>159</td>
                                    <td>SHREE MARUTI ASSOCIATES</td>
                                    <td>SHREE MARUTI RECOVERY AGENCY...161,BIRIYAKHEDA RATLAM M.P. PINCODE...457001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>160</td>
                                    <td>SHREE OMKAR ASSOCIATE</td>
                                    <td>KAMALA MILLS COMPOUNDSENAPATI BAPAT MARG,LOWER PARELMUMBAIMAHARASHTRA400013</td>
                                </tr>
                                <tr>
                                    <td>161</td>
                                    <td>Shree Ramehskrupa Solution</td>
                                    <td>OFFICE NO 504 5TH FLOOR WEST GATE 2 BUILDING AYODHYA CHOWK 150 FEET RING ROAD RAJKOT 360005</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>162</td>
                                    <td>shree recovery agency</td>
                                    <td>OFFICE NO 2 WARD 9/B,PLOT NO 792,HARIHAR SOCIETY</td>
                                </tr>
                                <tr>
                                    <td>163</td>
                                    <td>SHREE SAI ENTERPRISES</td>
                                    <td>GROUND & FIRST FLOOR Â"¬Ã´MOHARÂ"¬Ã¶. 17, HINDUSTAN COLONY WARDHA ROAD, NAGPUR MAHARASHRA, PIN 440015</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>164</td>
                                    <td>Shree Samartha Enterprises</td>
                                    <td>SHIVAJI PETH, THANE (WEST) - 400602</td>
                                </tr>
                                <tr>
                                    <td>165</td>
                                    <td>Shree Shyam Associate</td>
                                    <td>KHAIR ROAD NEAR KHAIRESHWAR CHAURAHA APPOSITE SBI BANK SHIVALIK MARKET,ALIGARH ,UTTAR PRADESH 202001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>166</td>
                                    <td>Shreeya Info Services</td>
                                    <td>K K MARKET OFFICE NO 58 B WING 6TH FLOOR BALAJI NAGAR DHANKAWADI PUNE 411043</td>
                                </tr>
                                <tr>
                                    <td>167</td>
                                    <td>SHRI KARNI ASSOCIATES</td>
                                    <td>GROUND FLOOR, SHOP NO.46,OSTWAL PLAZA-II, SUNDARWAS , UDAIPUR (RAJ.)313001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>168</td>
                                    <td>SHRI MAHAKAL AGENCY</td>
                                    <td>S - 167 , IN FRONT OF DURGA MANDIR , NEHRU NAGAR , KOTRA SULTANABAD , BHOPAL ( M.P )</td>
                                </tr>
                                <tr>
                                    <td>169</td>
                                    <td>SHRI MAHAKAL ENTERPRISES</td>
                                    <td>WARD NO. 38 GANDHI NAGAR AGYARAM COLONY VIDISHA</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>170</td>
                                    <td>Shri Sai Enterprises Business Solutions</td>
                                    <td>BUSINESS SQUARE PLOT NO-57, MAYUR COLONY, NEXT TO JOG HIGH SCHOOL, KOTHRUD</td>
                                </tr>
                                <tr>
                                    <td>171</td>
                                    <td>Shri Sai Enterprises Business Solutions</td>
                                    <td>BUSINESS SQUARE PLOT NO-57, MAYUR COLONY, NEXT TO JOG HIGH SCHOOL, KOTHRUD</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>172</td>
                                    <td>Shri Shyam Associates</td>
                                    <td>KHAIR ROAD NEAR KHAIRESHWAR CHAURAHA APPOSITE SBI BANK SHIVALIK MARKET,ALIGARH ,UTTAR PRADESH 202001</td>
                                </tr>
                                <tr>
                                    <td>173</td>
                                    <td>SHRI VINAYAK ASSOCIATES COLLECTION AGENCY</td>
                                    <td>GURUDWARA KE PASS KHEJRA ROAD CANTT GUNA 473001 MP</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>174</td>
                                    <td>Shubham Debt Recovery Agency</td>
                                    <td>17L/13A/10K OM NAGAR, RAJAPUR PRAYAGRAJ 211002 UP</td>
                                </tr>
                                <tr>
                                    <td>175</td>
                                    <td>Siddhi vinayak (Milan Tvs)</td>
                                    <td>307,3RD FLOOR OASIS TRADE CENTER ABOVE MILAN TVS SHOWROOM OPP RANI SATI GATE YN ROADS INDORE 452003</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>176</td>
                                    <td>Siddhi vinayak associates (Milan Tvs)</td>
                                    <td>307,3RD FLOOR OASIS TRADE CENTER ABOVE MILAN TVS SHOWROOM OPP RANI SATI GATE YN ROADS INDORE 452003</td>
                                </tr>
                                <tr>
                                    <td>177</td>
                                    <td>Siddhi Vinayak Enterprises</td>
                                    <td>202-203 2ND FLOOR ,METRO TOWER,VIJAY NAGAR INDORE 452010 MP</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>178</td>
                                    <td>SIDDHIVINAYAK SERVICES</td>
                                    <td>SR. NO. 13, SATAVWADI, HADAPSAR, PUNE - 411028</td>
                                </tr>
                                <tr>
                                    <td>179</td>
                                    <td>SINGH ENTERPRISES</td>
                                    <td>G.T.ROAD, DHANIPUR, ALIGARH- 202001, UTTAR PRADESH</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>180</td>
                                    <td>Sj Creative Intelligence</td>
                                    <td>BASEMENT FLOOR , SHOP NO 13 , PLOT NO. 174 , VIDHYUT NAGAR-C , CHITRAKOOT MARG , CHITRAKOOT NAGAR , JAIPUR , RAJASTHAN - 302021</td>
                                </tr>
                                <tr>
                                    <td>181</td>
                                    <td>SK Financial Services</td>
                                    <td>147, NIL, HASANRAJPUR, THARUWAPUR HARRAIYA, BASTI UTTAR PRADESH- 272129</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>182</td>
                                    <td>Soniya Moter Serivce</td>
                                    <td>CHANDAUSI CHAURAHA SAMBHAL UP 244302</td>
                                </tr>
                                <tr>
                                    <td>183</td>
                                    <td>Sparsh Enterprises</td>
                                    <td>NAVKAR LITTLE WANDER FLAT NO 101 DSK RANWARA BAVDHAN KH. PUNE 411021</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>184</td>
                                    <td>SREE VISHNU KERALA</td>
                                    <td>NO.3 PALAT MADHAVAN STREET, MAHALINGAPURAM,NUNGAMBAKKAM,CHENNAI-600 034</td>
                                </tr>
                                <tr>
                                    <td>185</td>
                                    <td>SRI JAGANNATH ASSOCIATES</td>
                                    <td>AT-BIDYADHARPUR PO CHHATIA JAIPUR ODISHA 754023</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>186</td>
                                    <td>SRI LAKSHMI AGENCIES</td>
                                    <td>FLAT NO:-502, PLOT NO:-410, SAMEEKSHA SPIRES, ROAD NO:-2/2, MATRUSRINAGAR MIYAPUR HYDEARBAD TELANGAN 500049</td>
                                </tr>
                                <tr>
                                    <td>187</td>
                                    <td>SRI LAKSHMI ASSOCIATES</td>
                                    <td>65-3-31A DWARKAMAI NILYAM NTR CIRCLE PATAMATA</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>188</td>
                                    <td>SRI LAKSHMI GANAPATHI SERVICES</td>
                                    <td>HNO 1-8-32&33 ,1ST FLOOR ,1ST PORTION TEMPLE ALWAL OPP PLOCE STATION SECUNDERABAD 500010 TELANGANA</td>
                                </tr>
                                <tr>
                                    <td>189</td>
                                    <td>SRI SAPTAGIRI ASSOCIATES</td>
                                    <td>23-88/8 R K NAGAR ANANDBAGH X ROADS MALKAJGIRI 500047</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>190</td>
                                    <td>Sriranga Associates</td>
                                    <td>NO 40 SLN NILAYA 5TH A CROSS RHCS LAYOUT ANNAPOORNESHWARI NAGARA NAGARABHAVI BENGALURU 560091</td>
                                </tr>
                                <tr>
                                    <td>191</td>
                                    <td>SRIVASTAVA & SONS</td>
                                    <td>2ND FLOOR REKHI MANSION, DAIGONAL ROAD , BISTUPUR JAMSHEDPUR 831001, OPP HOTEL RAJHANS</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>192</td>
                                    <td>SRM Associates</td>
                                    <td>SHOP NO 03,C-17 KUNJ VIHAR JAGRITI VIHAR MEERUT UP 250004</td>
                                </tr>
                                <tr>
                                    <td>193</td>
                                    <td>SRS ADVISORY PVT LTD</td>
                                    <td>132/A SOUTHERN AVENUE,DR.MEGHNAD SAHA SARANIGOL PARK, DHAKURIA KOLKATA WEST BENGAL 700029</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>194</td>
                                    <td>SSS Associates</td>
                                    <td>LOWER GROUND & FIRST FLOOR 340, MIG 80 FEET ROAD, ANNA NAGAR, MADURAI, 625020</td>
                                </tr>
                                <tr>
                                    <td>195</td>
                                    <td>Star Management Services</td>
                                    <td>A-21,3RD FLOOR ST NUMBER 19 NEAR SINGLA RESTAURANT MADHU VIHAR 110092</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>196</td>
                                    <td>Star Net Enterprises</td>
                                    <td>77 JAIL CHUNGI UNIVERSITY ROAD MEERUT UTTARPRADESH 250001</td>
                                </tr>
                                <tr>
                                    <td>197</td>
                                    <td>STUNNING CORPORATE SOLUTIONS</td>
                                    <td>A-61 SEVEN WONDERS SECTOR-16 NOIDA 201301</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>198</td>
                                    <td>SURE SAFE ADVISORY PVT LTD</td>
                                    <td>4 DR.SURESH SARKAR ROAD 3RD FLOOR ,AMANTRAN HOUSE KOLKATA-700014</td>
                                </tr>
                                <tr>
                                    <td>199</td>
                                    <td>SV ENTERPRISES</td>
                                    <td>RUPAM CENTER CINE PLANET, MUMBAI</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>200</td>
                                    <td>SVB Agency</td>
                                    <td>#125/74/1 OPP TO AGARA LAKE, BESIDE VANDANA SAROVAR APTS, 6TH CROSS, 3RD MAIN ROAD, JAKKASANDRA , KORAMANGALA 1ST BLOCK, BANGALORE-560034</td>
                                </tr>
                                <tr>
                                    <td>201</td>
                                    <td>SVB ENTERPRISES</td>
                                    <td>#125/74/1 OPP TO AGARA LAKE, BESIDE VANDANA SAROVAR APTS, 6TH CROSS, 3RD MAIN ROAD, JAKKASANDRA , KORAMANGALA 1ST BLOCK, BANGALORE-560034</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>202</td>
                                    <td>SVS SOLUTIONS</td>
                                    <td>PLOT NO 4 PAIGHA HOUSING COLONY PHASE -2 BEGUMPET HYDERABAD,TELANGANA 500003</td>
                                </tr>
                                <tr>
                                    <td>203</td>
                                    <td>Swami Samarth Multi Services</td>
                                    <td>PLOT NO 8 A PRATIK PLAZA PAHADE CORNER GARKHEDA PARISAR AURANGABAD 431009</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>204</td>
                                    <td>T&T Associate</td>
                                    <td>DHIPASAH WARD NO 11,PO-BARIPADA,PS-BARIPADA TOWN ,MAYURBHANJ,ODISHA 757001</td>
                                </tr>
                                <tr>
                                    <td>205</td>
                                    <td>Union Debt Solutions</td>
                                    <td>OFFICE NO-11,2ND FLOOR BANALI APARTMENT NEAR NAL STOP CHOWK ERANDWANE PUNE 411004</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>206</td>
                                    <td>United Associates</td>
                                    <td>GAYAKWAD COMPLEX AMARKANTAK ROAD DHANPURI RESINAL BURHAR DIST SAHDOL MP</td>
                                </tr>
                                <tr>
                                    <td>207</td>
                                    <td>Usha business soluations</td>
                                    <td>SHOP NO 11 NAKUL PATIL CHWAL OPP NAKUL PATIL BANGLOW DEVI CHOWK SHASTRI NAGAR DOMBIVLI WEST 421202</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>208</td>
                                    <td>V K Enterprises</td>
                                    <td>GROUND FLOOR HOTEL PREETI COMMERCIAL COMPLEX DHANGARWADI DIST SATARA MAHARASHTRA SATARA MAHARASHTRA415004</td>
                                </tr>
                                <tr>
                                    <td>209</td>
                                    <td>Vajra Enterprises</td>
                                    <td>3-45 FLAT NO 101, KRISHNA KUNJAM APPARTMENTS HANUMAN NAGAR CHAITHANYA PURI, DILSHUKH NAGAR, HYDERBAD TELANAGANA 500060</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>210</td>
                                    <td>Vani Associates</td>
                                    <td>PHASE 3 AKASHDEEP ENCLAVE ROORKEE HARIDWAR -247667</td>
                                </tr>
                                <tr>
                                    <td>211</td>
                                    <td>VAS Financial services</td>
                                    <td>PLOT NO. 24, TRIMULGIRI, KANTA BASTHI, BESIDE CHINA BAZAR LANE HYDERABAD, TELANGANA, INDIA, 500015</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>212</td>
                                    <td>Vigneshwara Enterprises</td>
                                    <td>1-8-522/36 FLAT NO 204, S V PAPAYA ESTATES, CHIKADPALLY HYD-500020</td>
                                </tr>
                                <tr>
                                    <td>213</td>
                                    <td>VINAYAGAR ENTERPRISES</td>
                                    <td>42 NORTH RAJA STREET, DISTT. TIRUVALLUR, TAMIL NADU 602001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>214</td>
                                    <td>Vkt Financial Services</td>
                                    <td>UNIT NO. 3, 4 & 5, SCO – BLOCK III & IV 3RD FLOOR, SPECIAL HUDA COMPLEX, SECTOR -19, FARIDABAD - 121002</td>
                                </tr>
                                <tr>
                                    <td>215</td>
                                    <td>YASHIKA ENTERPRISES</td>
                                    <td>GF-25 PRABHATAM PLAZA RAISEN ROAD PATEL NAGAR BHOPAL MP 462022</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>216</td>
                                    <td>YASHJYOTI DEBT CONSULTANCY PVT LTD</td>
                                    <td>S NO. 14/4, 14/5, 10, ECHS, VIVEKANAND SOCIETY, ERANDAWANE, PUNE, PUNE, MAHARASHTRA, 411004</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Recovery